/*
Masked the input execpt the visibleChars at the end
 */
export default function (value, { visibleCharsStart = 2, visibleCharsEnd = 4, ignoreWhitespace = false } = {}) {
  if (!value) {
    return value
  }
  const totalVisible = visibleCharsStart + visibleCharsEnd
  if (value.length <= totalVisible) return value
  if (!ignoreWhitespace) {
    return mask(value)
  } else {
    // maskes the string but ignores the whitespaces
    const { stringWithoutWhitespaces, whitespaceIndex } = striptWhiteSpaces(value)
    const maskedString = mask(stringWithoutWhitespaces)
    const maskedWithWhitepaces = readdWhitespaces(maskedString, whitespaceIndex)
    return maskedWithWhitepaces
  }

  function striptWhiteSpaces (targetString) {
    let stringWithoutWhitespaces = ''
    const whitespaceIndex = []
    const chars = targetString.split('')
    for (let i = 0; i < chars.length; i++) {
      const char = chars[i]
      if (char === ' ') {
        whitespaceIndex.push(i)
      } else {
        stringWithoutWhitespaces += char
      }
    }
    return {
      stringWithoutWhitespaces,
      whitespaceIndex
    }
  }
  function readdWhitespaces (targetString, whiteSpaceIndex) {
    const result = targetString.split('')
    for (const whitespace of whiteSpaceIndex) {
      result.splice(whitespace, 0, ' ')
    }
    return result.join('')
  }
  function mask (targetString) {
    const masked = targetString.length - totalVisible
    return `${targetString.substr(0, visibleCharsStart)}${''.padStart(masked, '*')}${targetString.substr(-visibleCharsEnd)}`
  }
}
