<template>
  <ValidationObserver
    ref="smsForm"
    slim
  >
    <div class="h-100">
      <div class="d-flex align-items-center flex-column justify-content-center h-100">
        <div
          id="login-card"
          class="card border-0 shadow"
        >
          <div
            v-if="error"
            class="card-header bg-danger-light text-danger border-0"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <i class="fas fa-exclamation-triangle text-danger" />
              </div>
              <div class="col pl-0">
                <h6 class="mb-0">
                  Fehler
                </h6>
                {{ getErrorMessage(error) }}
              </div>
            </div>
          </div>
          <div class="card-body p-5 text-center">
            <template
              v-if="!isRegistration"
            >
              <h5
                class="card-title text-center"
              >
                Geben Sie den Token ein, den Sie per SMS erhalten haben
              </h5>
              Versendet an {{ mask(user.twoFactor.SMS.mobileNumber, 0) }}
            </template>
            <template v-else>
              <template v-if="!mobilieNumberConfirmed">
                <h5>Welche Mobilfunknummer soll verwendet werden?</h5>
                <form @submit.prevent="setMobileNumber">
                  <basic-tel-input
                    v-model="mobileNumber"
                    label="Mobilfunknummer"
                  />
                  <div class="form-row">
                    <div class="col">
                      <button
                        class="btn btn-secondary w-100"
                        type="button"
                        @click="goBack()"
                      >
                        Zurück
                      </button>
                    </div>
                    <div
                      class="col"
                    >
                      <button
                        class="btn btn-primary w-100"
                        type="submit"
                      >
                        Senden
                      </button>
                    </div>
                  </div>
                </form>
              </template>
            </template>
            <form
              v-if="!isRegistration || mobilieNumberConfirmed"
              novalidate
              @submit.prevent="submit"
            >
              <p>
                Geben Sie nun den Code unten ein.
              </p>
              <basic-input
                v-model="token"
                label="2FA Token"
                class="mb-3"
              />
              <div class="form-row mb-3">
                <div class="col">
                  <button
                    class="btn btn-secondary w-100"
                    type="button"
                    @click="goBack()"
                  >
                    Abbrechen
                  </button>
                </div>
                <div
                  class="col"
                >
                  <button
                    class="btn btn-primary w-100"
                    type="submit"
                  >
                    Bestätigen
                  </button>
                </div>
              </div>
              <div class="text-left">
                <button
                  v-b-toggle.collapse-1
                  type="button"
                  class="btn btn-link pl-0"
                >
                  Code nicht erhalten?
                </button>
                <b-collapse id="collapse-1">
                  <p class="mb-0">
                    Es kann einige Minuten dauern, bis Sie den Code per SMS erhalten.
                  </p>
                  <button
                    class="btn btn-link pl-0"
                    type="button"
                    @click="sendToken"
                  >
                    SMS erneut versenden
                  </button>
                </b-collapse>

                <router-link
                  v-if="!isRegistration && otherMethodsAvailable"
                  :to="{name: '2faSelect', query: $route.query}"
                  class="d-block"
                >
                  Andere Methode verwenden
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import feathers from '@/api'
import BasicTelInput from '@/components/BaseComponents/BasicTelInput'
import BasicInput from '@/components/BaseComponents/BasicInput'
import mask from '@/filters/mask'
export default {
  components: {
    ValidationObserver,
    BasicTelInput,
    BasicInput
  },
  data: () => ({
    token: '',
    secret: '',
    mobileNumber: '',
    mobilieNumberConfirmed: false,
    registrationPending: false,
    error: null
  }),
  computed: {
    twoFactor () {
      return this.$store.getters['auth/user'].user.twoFactor
    },
    client () {
      return this.$store.getters['clients/find']().data[0]
    },
    isU2fAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.U2F && this.client.twoFactor.U2F.enabled
      const userAvailable = this.twoFactor.U2F && this.twoFactor.U2F.keyHandle && this.twoFactor.U2F.publicKey
      return globalAvailable && userAvailable
    },
    isTotpAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.TOTP && this.client.twoFactor.TOTP.enabled
      const userAvailable = this.twoFactor && this.twoFactor.TOTP && this.twoFactor.TOTP.secret
      return globalAvailable && userAvailable
    },
    otherMethodsAvailable () {
      return this.isU2fAvailable || this.isTotpAvailable
    },
    user () {
      return this.$store.getters['auth/user'].user
    },
    isRegistration () {
      const { SMS } = this.user.twoFactor
      return !SMS || !SMS.secret
    }
  },
  async created () {
    if (!this.user) return this.$router.push('/login')
    const { SMS } = this.$store.getters['auth/user'].user.twoFactor
    if (!SMS) {
      this.$set(this.$store.state.auth.user.user.twoFactor, 'SMS', {})
    }
    if (!this.isRegistration) {
      this.sendToken()
    }
  },
  methods: {
    mask,
    async goBack () {
      if (this.isRegistration) {
        await this.$router.push(this.$route.query.redirect || '/')
      } else {
        await this.$store.dispatch('auth/logout')
        location.href = '/login'
      }
    },
    async submit () {
      const payload = {
        strategy: '2fa',
        accessToken: this.$store.state.auth.accessToken,
        twoFactorMethod: 'sms',
        token: this.token,
        mobileNumber: this.mobileNumber
      }
      if (this.isRegistration) {
        payload.secret = this.secret
      }
      try {
        const valid = await this.$refs.smsForm.validate()
        if (!valid || this.pending) {
          return
        }
        this.pending = true
        this.error = null
        await this.$store.dispatch('auth/authenticate', payload)
        await this.$router.push(this.$route.query.redirect || '/')
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.pending = false
      }
    },
    sendToken () {
      return feathers.service('sms-authentication').create({})
    },
    async setMobileNumber () {
      this.registrationPending = true
      try {
        const valid = await this.$refs.smsForm.validate()
        if (!valid || this.pending) {
          return
        }
        this.pending = true
        this.error = null
        const secret = await feathers.service('sms-registration').create({ mobileNumber: this.mobileNumber })
        this.secret = secret
        this.mobilieNumberConfirmed = true
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.pending = false
      }
      this.registrationPending = false
    },
    getErrorMessage (error) {
      switch (error.code) {
      case 21211:
        return 'Sie haben keie valide Mobilfunknummer eingegeben'
      default: { return 'Der Server konnte Ihre anfrage nicht bearbeiten' }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#login-card {
  max-width: 500px;
}

</style>
